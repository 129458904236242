@import "../../../styles/variables";
@import "../../../styles/animations";
@import "../../../styles/common/row";

.details {
  &__description {
    color: $w4;
    font-size: 14px;
    text-align: center;
    padding: 10px 5px 0 5px;
  }

  &__countries {
    display: flex;
    justify-content: space-between;
    margin: 10px 10px;
  }

  &__country {
    padding: 5px 10px;
    color: $w3;
    background-color: $w8;
    border-radius: 5px;
    align-items: center;
    display: flex;

    & > span {
      padding: 0 2px;
    }
  }

  &__line {
    flex: 1;
    height: 2px;
    background-color: $w7;
    align-self: center;
    margin: 0 10px;
  }
}

@import "../../styles/variables";
@import "../../styles/common/button";
@import "../../styles/common/modal";

.example {
  padding: 10px 10px 0 10px;
  margin: 0 auto;
  max-width: 400px;
  border-radius: 5px;
  min-height: 170px;

  &__container {
    border-radius: 10px;
  }

  &__search {
    height: 20px;
    max-width: 100%;
    margin: 5px;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    padding: 10px;
    // border: $w7 solid 2px;
    border: $m1 solid 2px;
    font-family: "IBM Plex Sans", "Roboto", sans-serif;
    &--empty {
      &::before {
        content: "Enter a Country...";
      }
      color: $w4;
      border: $w7 solid 2px;
    }
    &--disabled {
      &::before {
        content: "Enter a Country...";
      }
      cursor: not-allowed;
      border: $w7 solid 2px;
      background-color: $w6;
      color: $w4;
    }
  }

  &__type {
    align-items: center;
  }
}

.link {
  display: flex;
  padding: 10px 20px;
  border-radius: 10px;
  color: $w4;
  border: 2px solid $p3;

  background-color: $w9;
  text-decoration: underline;
  font-weight: normal;

  &:hover {
    color: $w1;
    background-color: $w8;
    cursor: pointer;
  }

  &:focus {
    outline: $m1 solid 2px;
    z-index: 1;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.modal {
  &__text {
    line-height: 1.5;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__subtitle {
    text-align: center;
    margin: 20px 0 10px 0;

    line-height: 1.5;
    font-size: 18px;
  }

  &__swiper {
    margin-bottom: 30px;
    cursor: grab;
  }

  &--link {
    text-decoration: underline;
  }
}

.swiper {
  &__title {
    margin: 0 auto;
    text-align: center;
  }

  &__text {
    padding-top: 25px;
    margin: 0 auto 35px auto;
    max-width: 300px;
    text-align: center;
    line-height: 1.5;
  }

  &-pagination-bullet {
    background-color: $w1;
  }

  &-pagination-bullet-active {
    background-color: $p0;
  }

  &-button-prev {
    color: $p0;
  }

  &-button-next {
    color: $p0;
  }
}

.category__container {
  min-height: 160px;
}

@import "../../styles/variables";
@import "../../styles/common/modal";

.subtitle {
  text-align: center;
  margin-top: 20px;
}

.stat {
  display: inline-block;
  text-align: center;
  width: 60px;

  &__text {
    font-size: 24px;
    background-color: $w8;
    border-radius: 5px;
    padding: 5px 0px;
    margin-bottom: 5px;
    border: $p3 solid 2px;
  }

  &__container {
    display: flex;
    justify-content: space-around;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px 0px 30px 0px;
  }

  &__label {
    font-size: 12px;
  }
}

.guess-count {
  font-size: 24px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  border-radius: 5px;
  background-color: $w8;
  padding: 5px;
  max-width: 300px;
  margin: 0 auto 20px auto;
  &__summary {
    padding: 5px;
  }

  &__text {
    text-align: center;
    color: $w4;
    margin: 10px auto;
    font-family: "IBM Plex Mono", "Roboto Mono", monospace;
    font-size: 14px;
    max-width: 250px;
    line-height: 1.5;
  }
}

.country-guess {
  background-color: $w9;
  padding: 5px 0;
  margin-bottom: 5px;
  padding-left: 10px;
  max-width: 320px;
  align-items: center;
  display: flex;

  &__container {
    padding: 20px 0px;
    max-width: 320px;
    margin: 0 auto;
    flex-direction: column;
    display: flex;
  }
}

.plot {
  background-color: $w9;
  border-radius: 10px;

  &__container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
}

.share-section {
  padding: 5px 0;
}

@import "../../styles/variables";

.category-info {
  &__top {
    padding: 0 0 10px 0;
    color: $w4;
  }
  &__bottom {
    padding: 10px 0 0px 0;
    color: $w4;
  }
}

.data {
  &__container {
    padding: 20px 20px 100px 20px;
    max-width: 500px;
    margin: 0 auto;
  }

  &__th {
    text-align: left;
    padding: 0 10px;
    outline: 2px solid $p3;
    vertical-align: middle;
    background-color: $p1;
    color: $w1;

    &:first-child {
      border-radius: 10px 0 0 0;
      text-align: center;
    }

    &:last-child {
      border-radius: 0 10px 0 0;
      text-align: center;
    }
    &:nth-child(2) {
      text-align: left;
    }
  }

  &__tr {
    height: 30px;
    background-color: $w10;
    &:nth-child(even) {
      background-color: $w9;
    }
    &:last-child {
      & > td:first-child {
        border-radius: 0 0 0 10px;
      }
      & > td:last-child {
        border-radius: 0 0 10px 0;
      }
    }
  }

  &__td {
    padding: 0 10px;
    color: $w1;
    outline: 2px solid $p3;
    vertical-align: middle;
    height: 30px;
    width: 100%;

    &:first-child {
      text-align: center;
      width: 40px;
    }

    &:nth-child(3) {
      min-width: 100px;
      text-align: center;
    }
    &--country {
      vertical-align: middle;
    }
  }
}

.select {
  display: flex;
  height: 40px;
  padding: 0 10px;
  margin: 5px auto;
  width: 100%;
  background-color: $w9;
  color: $w1;
  border: 2px solid $m1;
  border-radius: 10px;

  &:hover {
    border: 2px solid $m0;
  }

  &:focus {
    background-color: $w7;
  }

  &__container {
    position: fixed;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 420px;
    width: 100%;
    z-index: 1;
  }
}

.option {
  // border-bottom: 2px solid $w10;
  padding: 5px 0px 5px 10px;
  background-color: $w8;
  color: $w4;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: $p2;
    color: $w1;
  }

  &--selected {
    background-color: $w6;
    color: $w1;
  }

  &__container {
    margin: 0 5px;
    max-height: 200px;
    border-top: 5px solid $w10;
    overflow-y: scroll;
  }
}

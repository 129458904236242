@import "../../../styles/variables";
@import "../../../styles/common/row";
@import "../../../styles/animations";

.title {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $w9;
  color: $w4;
  align-items: center;
  font-family: "IBM Plex Mono", "Roboto Mono", monospace;

  &__left {
    margin-left: 10px;
    margin-right: auto;
    align-self: left;
  }

  &__right {
    margin-left: auto;
    margin-right: 10px;
    align-content: right;
    align-self: right;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &:hover {
    color: $w1;
    background-color: $w8;
    cursor: pointer;
  }

  &:focus {
    outline: $m1 solid 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

@import "../styles/variables";

.main__container {
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  padding-bottom: 60px;
}

.tip {
  &__container {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.25;
    padding: 20px 0 0 0;
  }

  &__text {
    color: $w4;
  }
}

@import "../../../styles/variables";
@import "../../../styles/common/row";
@import "../../../styles/animations";

.position-symbol {
  font-family: "IBM Plex Mono", "Roboto Mono", monospace;
  justify-content: center;
  width: 10px;
  border-right: none !important;
}

.rank-number {
  font-family: "IBM Plex Mono", "Roboto Mono", monospace;
  justify-content: center;
  width: 50px;
  border-right: none !important;
}

.country-name {
  flex: 1;
  cursor: pointer;

  &:focus {
    outline: $m0 solid 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  @media screen and (max-width: 330px) {
    transition-duration: 300ms;
    font-family: "IBM Plex Sans Condensed", "Roboto Condensed", sans-serif;
    font-size: 14px;
  }
}

@import "../variables";

.btn {
  display: flex;
  align-items: center;
  user-select: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;

  &--wide {
    width: 100%;
    display: flex;
    margin: 10px auto;
    align-items: center;
    justify-content: center;
    max-width: 320px;
    padding: 10px 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: $w10;
    color: $w1;
    border: $m1 solid 2px;

    &:hover {
      color: $w2;
      background-color: $m2;
      border: $m2 solid 2px;
      cursor: pointer;
    }

    &:focus {
      outline: $m1 solid 2px;
      border: $m1 solid 2px;
      background-color: $m2;
    }

    &:focus:not(:focus-visible) {
      border: $m1 solid 2px;
      background-color: $w10;
    }

    &:active {
      background-color: $m0;
      transition-duration: 100ms;
    }
  }

  &--icon {
    background-color: $w10;
    aspect-ratio: 1;
    display: inline-block;
    width: auto;
    border-radius: 5px;
    padding: 5px;
    opacity: 0.66;

    &:hover {
      background-color: $w9;
      opacity: 1;
    }

    &:focus {
      background-color: $w8;
      border: $m1 solid 2px;
      opacity: 1;
    }

    &:active {
      background-color: $w8;
      opacity: 1;
    }

    &:focus:not(:focus-visible) {
      border: none;
    }
  }

  &--inactive {
    cursor: not-allowed;
    background-color: $w6;

    &:focus {
      box-shadow: none;
      border: $m1 solid 2px;
    }

    &:focus:not(:focus-visible) {
      border: none;
    }
  }

  &--disabled {
    opacity: 0.33;
    &:hover {
      background-color: $w10;
      opacity: 0.33;
      cursor: not-allowed;
    }
  }

  &--chip {
    background-color: $w9;
    outline: $p3 solid 2px;
    color: $w4;
    display: inline-flex;
    justify-content: center;
    margin: 5px;
    padding: 5px 10px;
    font-size: 14px;

    & > img {
      margin-right: 10px;
    }

    & > span {
      text-align: left;
    }

    &:hover {
      color: $w1;
      background-color: $w8;
      cursor: pointer;
    }

    &:focus {
      outline: $m1 solid 2px;
      color: $w1;
    }

    &:focus:not(:focus-visible) {
      outline: $p3 solid 2px;
    }

    &__group {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--bottom {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }
}

@import "../variables";
@import "../animations";
@import "../common/button";

.display {
  &__group {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    margin: 0 5px 0 5px;

    @media screen and (min-height: 660px) {
      transition-duration: 300ms;
      padding: 10px 0;
    }

    &:not(:has(.display__row)) .display__title,
    .title {
      border-radius: 10px;
    }

    &:has(.display__row) .display__title {
      border-radius: 10px 10px 0 0;
    }

    & .display__row:last-child {
      border-radius: 0 0 10px 10px;
    }

    & .display__row:last-child > .display__cell:first-child {
      border-radius: 0 0 0 10px;
    }

    & .display__row:last-child > .display__cell:last-child {
      border-radius: 0 0 10px 0;
    }

    & .display__row:last-child > .display__line {
      border-radius: 0 0 10px 10px;
    }
  }

  &__title {
    height: 30px;
    border: 2px solid $p3;

    background-color: $w9;
    color: $w4;
  }

  &__row {
    display: flex;
    background-color: $w10;

    justify-content: center;
    vertical-align: middle;

    &--real > span {
      border-top: 0px;
    }
  }

  &__details {
    display: block;
    height: auto;
    border: 2px solid $p3;
    border-top: 0;
    transition-duration: 300ms;
    animation: animation-details-open 300ms ease;
  }

  &__cell {
    padding: 0 10px;
    color: $w1;
    border: 2px solid $p3;
    background-color: $w10;
    display: flex;
    align-items: center;
    height: 40px;

    @media screen and (max-height: 660px) {
      height: 35px;
      transition-duration: 300ms;
    }

    @media screen and (max-height: 620px) {
      height: 30px;
      transition-duration: 300ms;
    }
  }

  &__line {
    background-color: $p1;
    font-size: 14px;
    width: 100%;
    height: 25px;
    color: $w3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.active {
  &--00 {
    background-color: $p1;
    animation-duration: 1000ms;
    animation-name: animation-active--00;
    animation-timing-function: ease;
  }
  &--10 {
    background-color: $p1;
    animation-duration: 1000ms;
    animation-name: animation-active--10;
    animation-timing-function: ease;
  }
  &--20 {
    background-color: $p1;
    animation-duration: 1000ms;
    animation-name: animation-active--20;
    animation-timing-function: ease;
  }
  &--30 {
    background-color: $p1;
    animation-duration: 1000ms;
    animation-name: animation-active--30;
    animation-timing-function: ease;
  }
  &--01 {
    background-color: $p1;
    animation-duration: 1000ms;
    animation-name: animation-active--01;
    animation-timing-function: ease;
  }
  &--11 {
    background-color: $p1;
    animation-duration: 1000ms;
    animation-name: animation-active--11;
    animation-timing-function: ease;
  }
  &--21 {
    background-color: $p1;
    animation-duration: 1000ms;
    animation-name: animation-active--21;
    animation-timing-function: ease;
  }
  &--31 {
    background-color: $p1;
    animation-duration: 1000ms;
    animation-name: animation-active--31;
    animation-timing-function: ease;
  }
  &--00-tut {
    background-color: $p1;
    animation-duration: 3000ms;
    animation-name: animation-active--00;
    animation-timing-function: ease;
  }
  &--10-tut {
    background-color: $p1;
    animation-duration: 3000ms;
    animation-name: animation-active--10;
    animation-timing-function: ease;
  }
}
